"use client";

import React from "react";
import { useAppDispatch } from "@/redux/hook";
import { setPopupId } from "@/redux/features/ShopSlice";
import useNotification from "@/lib/hooks/useNotification";
import useTranslationAPI from "@/lib/hooks/useTranslationApi";

import { StoryJson } from "@/types/Story.type";
import Website from "@/common/constants/webstite";

import Image from "@/components/ui/Image";
import LinkElement from "@/components/LinkElement";
import DataEmpty from "~components/empty/DataEmpty";
import NotificationIcon from "@/components/icons/NotificationIcon";
import LoadingTinySkeleton from "~components/skeleton/LoadingTinySkeleton";
import ImageConstant from "@/common/constants/image";

const NotificationList = ({
  data,
  errors,
  type = "new",
}: {
  data: StoryJson[];
  errors: string[];
  type?: "recent" | "new";
}) => {
  return (
    <>
      {errors.length > 0 ? (
        <span className="text-red-500">{errors[0]}</span>
      ) : (
        <>
          {data.length > 0 ? (
            <>
              {data.map((item: StoryJson, index: number) => (
                <LinkElement
                  key={index}
                  href={`${Website.LINK.PAGE.BLOG}/${
                    item?.collections[0]?.handle || "collection"
                  }/${item.handle}`}
                  className={`flex justify-between items-center gap-3 ${
                    type === "new" ? "bg-gray-200" : "bg-white"
                  } text-gray-500`}
                >
                  <Image
                    width={100}
                    height={100}
                    src={item.images?.[0]?.url || ""}
                    alt="photo"
                    className="w-[100px] h-[100px] object-cover"
                    placeholder={"blur"}
                    blurDataURL={ImageConstant.BlurDataURL}
                  />
                  <div className="flex-1 pr-[18px]">
                    <h3
                      className={`text-base ${
                        type === "new" ? "font-bold" : "font-medium"
                      } leading-[22px] line-clamp-2`}
                    >
                      {item.title}
                    </h3>
                  </div>
                </LinkElement>
              ))}
            </>
          ) : (
            <DataEmpty className="py-4" />
          )}
        </>
      )}
    </>
  );
};

const HeaderTopRight = () => {
  const dispatch = useAppDispatch();
  const { notifications: data, errors, loading, total } = useNotification();
  const { t } = useTranslationAPI([
    "order_tracking",
    "recent_notification",
    "new_notification",
  ]);

  return (
    <div className="justify-end items-center gap-[10px] hidden md:flex md:w-[50%]">
      <p
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setPopupId("popup-order-status"));
        }}
        className="text-base text-gray-500 leading-[17px] cursor-pointer"
      >
        {/* Theo dõi đơn hàng */}
        {t("order_tracking")}
      </p>
      <div className="relative group hover:after:absolute hover:after:bottom-[-20px] hover:after:right-0 hover:after:w-[70px] hover:after:h-[30px] hover:hover:after:content-[''] hover:after:bg-[transparent]">
        <div className="relative mr-2">
          <NotificationIcon className="min-w-[24px] cursor-pointer" />
          {total > 0 && (
            <span className="absolute flex justify-center items-center w-[15px] h-[15px] leading-[15px] text-[8px] font-bold text-white bg-red-500 rounded-full bottom-[5px] right-[-4px]">
              {total}
            </span>
          )}
        </div>
        <div className="absolute left-auto right-0 bg-white border border-gray-300 shadow-2xl w-[380px] p-4 pr-0 hidden group-hover:block z-50 max-h-[70vh] overflow-y-auto">
          {loading ? (
            <LoadingTinySkeleton />
          ) : (
            <div className="flex flex-col gap-[10px] max-h-[450px] overflow-y-auto scrollbarCustom pr-4">
              <h2 className="text-gray-500 text-base font-medium">
                {/* Thông báo mới */}
                {t("new_notification")}
              </h2>
              <div className="flex flex-col gap-[2px]">
                <NotificationList data={data.new} errors={errors.new} />
              </div>
              <h2 className="text-gray-400 text-base font-medium">
                {/* Thông báo gần đây */}
                {t("recent_notification")}
              </h2>
              <div className="flex flex-col gap-[2px]">
                <NotificationList
                  data={data.recent}
                  errors={errors.recent}
                  type="recent"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderTopRight;
