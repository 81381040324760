"use client";

import React from "react";
import { cn } from "@/utils/utils";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

import { EmptyIcon } from "@/components/icons";

type Props = {
  translateKey?: string;
  className?: string;
  classNameIcon?: string;
  classNameText?: string;
};

export default function DataEmpty({
  translateKey,
  className,
  classNameIcon,
  classNameText,
}: Props) {
  const { t } = useTranslationApi([
    translateKey ? translateKey : "",
    "data_empty",
  ]);
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-2 py-6",
        className
      )}
    >
      <EmptyIcon
        className={cn(`text-gray-400 w-12 h-12 min-w-12`, classNameIcon)}
      />
      <p
        className={cn(
          `text-left text-gray-400 text-base font-medium`,
          classNameText
        )}
      >
        {t(translateKey ? translateKey : "data_empty")}
      </p>
    </div>
  );
}
