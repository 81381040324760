"use client";
import Popup from "@/components/Popup";
import SuccessIcon from "@/components/icons/SuccessIcon";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import React from "react";

type Props = {
  handleOnClose: () => void;
};

const LetterSuccessPopup = ({ handleOnClose }: Props) => {
  const translate = {
    newsletter_success_title: "newsletter_success_title",
    newsletter_success_desc1: "newsletter_success_desc1",
    newsletter_success_desc2: "newsletter_success_desc2",
  };

  const { t } = useTranslationApi(Object.values(translate));

  return (
    <div className="block w-full bg-transparent">
      <Popup
        classNameCloseButton="absolute right-[20px] top-[10px]"
        classNameContent={
          "rounded-[8px] px-[47px] py-[32px] bg-white w-[330px] h-fit"
        }
        popupId={"newsletter-success-popup"}
        handleOnClose={handleOnClose}
        centered={true}
      >
        <div className="text-base text-center text-gray-500">
          <div className="flex justify-center mb-2">
            <SuccessIcon />
          </div>
          <p className="font-bold">{t(translate.newsletter_success_title)}</p>
          <div className="mt-4">
            <p className="text-sm">{t(translate.newsletter_success_desc1)}</p>
            <p className="text-sm">{t(translate.newsletter_success_desc2)}</p>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default LetterSuccessPopup;
