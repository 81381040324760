import BaseApi from "@/common/api/BaseApi";
import StoryCollection from "@/common/collections/StoryCollection";
import StoryModel from "@/common/models/StoryModel";
import { callApi, callApiV2 } from "@/lib/config/Api";
import {
	FilterNotification,
	FilterStory,
	StoryCollectionJson,
	StoryJson,
} from "@/types/Story.type";
import Helper from "@/utils/helper";

export async function getListStories(filters?: FilterStory) {
	const apiParams = Helper.convertFilterToParams(filters || {});

	const res = await callApiV2<StoryCollectionJson>(
		`/stories/public${apiParams}`,
		"get"
	);
	return res;
}

export async function getListStoriesAnalysis(filters?: FilterStory) {
	const apiParams = Helper.convertFilterToParams(filters || {});
	const res = await callApiV2<StoryCollectionJson>(
		`/stories/public/analysis${apiParams}`,
		"get"
	);
	return res;
}

export async function getListStoriesRelated(filters?: FilterStory) {
	const apiParams = Helper.convertFilterToParams(filters || {});
	const res = await callApiV2<StoryCollectionJson>(
		`/stories/public/related${apiParams}`,
		"get"
	);
	return res;
}

export async function getStoryDetails(id: number | string) {
	const res = await callApiV2<StoryJson>(`/stories/public/${id}`, "get");
	return res;
}

export async function getListNotification(filters: FilterNotification) {
	const apiParams = Helper.convertFilterToParams(filters);
	const res = await callApiV2<StoryCollectionJson>(
		`/stories/public${apiParams}`,
		"get"
	);
	return res;
}
