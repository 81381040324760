"use client";

import React from "react";
import { cn } from "@/utils/utils";
import { useAppSelector } from "@/redux/hook";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

import { MenuItemMobile } from "@/types/Setting.type";

import Image from "@/components/ui/Image";
import NavLink from "@/components/helpers/NavLink";
import MapElements from "@/components/MapElements";
import HeaderMidSearch from "~components/header/desktop/HeaderMid/HeaderMidSearch";
import ProductsRecentView from "@/app/products/[...handle]/_components/productsRecentView/ProductsRecentView";
import ImageConstant from "@/common/constants/image";
import useStoreId from "@/lib/hooks/useStoreId";

export default function HeaderTinyMenuSearch({
  categories,
  onClick,
}: {
  categories: MenuItemMobile[];
  onClick: () => void;
}) {
  const { t } = useTranslationApi(["search"]);

  // const store_id = useAppSelector((state) => state.ShopReducer.storeId);
  const {data : store_id} = useStoreId()

  return (
    <div className="fixed top-[70px] left-0 right-0 bottom-[0] pb-[200px] h-[100vh] overflow-y-scroll z-10 bg-white">
      <div>
        <div>
          <p className="text-gray-500 text-base my-[20px] font-[700] text-center">
            {/* Tìm kiếm */}
            {t("search")}
          </p>
          <div className="w-[92%] mx-auto mb-[20px]">
            <HeaderMidSearch />
          </div>
          <div className="flex flex-col gap-[8px]">
            <MapElements
              data={categories}
              render={(item, index) => (
                <div className=" " onClick={onClick}>
                  <NavLink
                    key={index}
                    href={item.link}
                    className="text-gray-500 group flex items-center justify-between gap-[10px] bg-gray-200"
                  >
                    <p
                      className={cn(
                        "font-[700] p-[16px] text-base uppercase group-hover:text-red-500 transition-all",
                        {
                          "text-red-500": [
                            "namperfume Favorites",
                            "Deal Thơm",
                          ].includes(item.title),
                        }
                      )}
                    >
                      {item.title}
                    </p>
                    <Image
                      width={100}
                      height={100}
                      src={item.image}
                      alt={item.title}
                      placeholder={"blur"}
                      blurDataURL={ImageConstant.BlurDataURL}
                    />
                  </NavLink>
                </div>
              )}
            />
          </div>
        </div>
        <div className="mt-6">
          <ProductsRecentView store_id={store_id} />
        </div>
      </div>
    </div>
  );
}
