"use client";

import { useForm } from "react-hook-form";
import { useAppDispatch } from "@/redux/hook";
import React, { useEffect, useState } from "react";
import { setPopupId } from "@/redux/features/ShopSlice";
import { postNewsLetter } from "@/lib/api/shop/newsletter";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

import BaseApi from "@/common/api/BaseApi";

import Button from "@/components/ui/Button";
import LetterPopup from "./children/LetterPopup";
import InputBorder from "@/components/ui/InputBorder";
import LetterErrorPopup from "./children/LetterErrorPopup";
import LetterSuccessPopup from "./children/LetterSuccessPopup";

type IFormInput = {
	contact_id: string;
};

const translate = {
	newsletter_input_email: "newsletter_input_email",
	newsletter_input_email_req: "newsletter_input_email_req",
	newsletter_error_input_phone: "newsletter_error_input_phone",
	newsletter_button: " newsletter_button",
};

const Newsletter = () => {
	const {
		handleSubmit,
		setError: setErrorsForm,
		getValues,
		setValue,
		formState: { errors: errorsForm },
		control,
		clearErrors,
		resetField
	} = useForm<IFormInput>({
		reValidateMode: "onChange",
		mode: "onSubmit",
		defaultValues: {
			contact_id: "",
		},
	});
	const dispatch = useAppDispatch();
	const { t } = useTranslationApi([
		"footer_news_sell",
		"newsletter_input_email",
		"newsletter_input_email",
		"newsletter_input_email_req",
		"newsletter_error_input_phone",
		"newsletter_error_input_email",
		"newsletter_button",
	]);
	//state
	const [loading, setLoading] = useState<boolean>(false);
	const [contactId, setContactId] = useState<string>("");
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isSucess, setIsSucess] = useState<boolean>(false);
	const [errorApi, setErrorApi] = useState<string[]>([]);
	//authpopup

	const onNewsLetter = async (formData: IFormInput, e: any) => {
		e.preventDefault();
		setLoading(true);
		try {
			const response = await postNewsLetter({
				contact_id: formData.contact_id.trim(),
				time: Math.floor(Math.random() * 100),
			});
			setContactId(getValues("contact_id").trim());
			clearErrors();
			resetField("contact_id")
			dispatch(setPopupId("newsletter-popup"));
		} catch (error) {
			setErrorApi(BaseApi.handleError(error).errors);
			dispatch(setPopupId("newsletter-error-popup"));
		} finally {
			setLoading(false);
		}
	};

	const onErrors = () => {
		// if (errorsForm.contact_id && errorsForm.contact_id.message) {
		//   setErrors([errorsForm.contact_id.message]);
		// }
	};

	useEffect(() => {
		if (isSucess) {
			dispatch(setPopupId("newsletter-success-popup"));
		} else {
			dispatch(setPopupId(""));
		}
	}, [isSucess]);

	return (
		<>
			<p className="mb-[16px] text-gray-300 font-[700] text-sm">
				{t("footer_news_sell")}
			</p>
			<form
				onSubmit={handleSubmit(onNewsLetter, onErrors)}
				className="flex items-center gap-[10px] mb-4">
				<div className="w-full">
					<InputBorder
						name="contact_id"
						control={control}
						placeholder={t("newsletter_input_email")}
						className="w-full h-[34px] p-[8px] rounded-[4px] border border-spacing-1 border-gray-400 bg-transparent focus:outline-none text-gray-300 placeholder:text-gray-400 text-sm leading-[15px]"
						rules={{
							validate: (value: string) => {
								if (value.trim().length === 0) {
									return t("newsletter_input_email_req");
								} else {
									if (!isNaN(Number(value.trim()))) {
										//phone
										const phoneRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
										if (phoneRegex.test(value.trim())) {
											// return true;
											// return "Số điện thoại chưa đúng định dạng. Vui lòng nhập lại.";
											return t("newsletter_error_input_email");
										} else {
											// return "Số điện thoại chưa đúng định dạng. Vui lòng nhập lại.";
											return t("newsletter_error_input_email");
										}
									} else {
										//email
										const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

										if (emailRegex.test(value)) {
											return true; // Email hợp lệ
										} else {
											return t("newsletter_error_input_email");
										}
									}
								}
							},
						}}
						showError={false}
					/>
				</div>

				<Button
					disabled={loading}
					loading={loading}
					label={t("newsletter_button")}
					className="overflow-visible w-fit"
					type="submit"
					mode="lightgray"
				/>
			</form>
			{errorsForm.contact_id &&
				errorsForm.contact_id.message !== "ERROR_CONTACTID_EXIST" && (
					<p className="text-xs text-red-500 mt-[6px]">
						{errorsForm.contact_id.message}
					</p>
				)}
			{/* {errors.length > 0 && !errors.includes("ERROR_CONTACTID_EXIST") ? (
          <p className="text-xs text-red-500 absolute mt-[6px]">{errors[0]}</p>
        ) : null} */}
			{contactId.length > 0 && (
				<LetterPopup
					contact_id={contactId}
					isSucess={isSucess}
					setIsSucess={setIsSucess}
				/>
			)}
			<LetterSuccessPopup
				handleOnClose={() => {
					dispatch(setPopupId(""));
					setIsSucess(false);
				}}
			/>
			{errorApi.length > 0 && (
				<LetterErrorPopup
					errors={errorApi}
					handleOnClose={() => {
						dispatch(setPopupId(""));
						setErrorApi([]);
						clearErrors();
					}}
				/>
			)}
		</>
	);
};

export default Newsletter;
