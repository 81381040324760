"use client";

import { useCookies } from "react-cookie";
import { useRouter } from "next/navigation";
import { logout } from "@/lib/api/account/auth";
import React, { useEffect, useState } from "react";
import { setReload } from "@/redux/features/ShopSlice";
import { setUser } from "@/redux/features/LoyaltySlice";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { signIn, signOut} from "next-auth/react";
import useGetRewardConstant from "@/lib/hooks/useGetRewardConstant";
import { setCartLocal, setCartDetail } from "@/redux/features/CartSlice";

import { AccountJson } from "@/types/Auth.type";

import HeaderRank from "./HeaderRank";
import LinkElement from "@/components/LinkElement";
import UserIcon from "@/components/icons/UserIcon";
import FacebookIcon from "@/components/icons/FacebookIcon";
import GoogleFilledIcon from "@/components/icons/GoogleFilledIcon";
import FacebookFilledIcon from "@/components/icons/FacebookFilledIcon";
import { getCustomer } from "@/lib/api/account/customer";
import { setCustomerInfo } from "@/redux/features/CustomerSlice";
import useCustomer from "@/lib/hooks/useCustomer";

const translate = {
  login_heading: "login_heading",
  register_heading: "register_heading",
  hi: "hi",
  logout: "logout",
  login_to_join_us: "login_to_join_us",
  membership: "membership",
  account_info: "account_info",
  my_account: "my_account",
};

const HeaderCredentials = () => {
  const { t } = useTranslationApi(Object.values(translate));

  const router = useRouter();
  const { data: user, isLoading } = useCustomer();

  const dispatch = useAppDispatch();
  //state
  const onLogin = async () => {
    const res = await signIn();
  };

  const onLogout = async () => {
    try {
      const res = await logout();
      if (res) {
        try {
          await signOut({ redirect: true, callbackUrl: "/" });
        } catch (err) {}
      }
    } catch (error) {}
  };

  // const data = useGetRewardConstant({
  //   rank_type_id : userInfo?.user.id
  // })

  return (
    <div className="flex-shrink-0 group hover:after:absolute hover:after:bottom-[-28px] hover:after:left-0 hover:after:w-[140px] hover:after:h-[35px] hover:after:content-[''] after:bg-[transparent] hover:after:text-[transparent] z-20">
      <div className="text-base text-gray-500 relative cursor-pointer transition-all flex justify-center items-center gap-[6px]">
        <UserIcon className="min-w-[24px] w-[24px] h-[24px] p-1" />
        {isLoading ? (
          <p>...</p>
        ) : (
          <>
            {user && user?.id > 0 ? (
              <>
                <LinkElement
                  href={"/account"}
                  title={t(translate.my_account)}
                  className="text-base text-gray-500 transition-all hover:text-red-500 line-clamp-1 min-w-max pr-2 border-r border-gray-200 leading-[17px]"
                >
                  Hi, <strong>{user.full_name}</strong>
                </LinkElement>
                {/* <LinkElement
							href={"/account/membership"}
							// title="Hạng thành viên"
							title={t(translate.membership)}>
							<HeaderRank />
						</LinkElement> */}
              </>
            ) : (
              <div className="h-full flex items-center">
                <LinkElement
                  href={"/account/login"}
                  className="text-base text-gray-500 text-right leading-[17px] transition-all hover:text-red-500"
                >
                  {/* Đăng nhập */}
                  {t(translate.login_heading)}
                </LinkElement>
              </div>
            )}
          </>
        )}

        {/* Modal */}
        <div className=" absolute group-hover:block hidden top-full mt-[16px] right-0 z-10 bg-white border border-gray-300 drop-shadow-sm max-w-max   ">
          {user ? (
            <ul className="min-w-[200px] divide-y divide-gray-300 px-[20px] mb-[20px]">
              <li className="cursor-pointer">
                <div className="flex items-center gap-[20px] py-[16px]">
                  <UserIcon className="min-w-[30px] w-[30px] h-[30px]" />
                  <div className="flex flex-col gap-1">
                    <p className="text-lg font-[700] text-gray-500 h-[20px] whitespace-nowrap">
                      {/* Chào bạn, */}
                      {t(translate.hi)},
                    </p>
                    <p className="text-sm text-gray-500 h-[20px]">
                      {user?.full_name}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-[10px]">
                  <LinkElement
                    href={"/account"}
                    className="text-base text-gray-500 py-[8px] hover:text-red-500 transition-all"
                  >
                    {/* Thông tin tài khoản */}
                    {t(translate.account_info)}
                  </LinkElement>
                </div>
              </li>
              <li className="py-[8px] cursor-pointer">
                <p
                  onClick={() => {
                    onLogout();
                  }}
                  className="text-base text-gray-500 hover:text-red-500 transition-all"
                >
                  {/* Đăng xuất */}
                  {t(translate.logout)}
                </p>
              </li>
            </ul>
          ) : (
            <ul className="divide-y divide-gray-300 mb-[20px]">
              <li className="px-[20px] py-[16px] cursor-pointer  ">
                <div className="flex items-center gap-[20px]">
                  <UserIcon className="min-w-[30px] w-[30px] h-[30px]" />
                  <div className="flex flex-col gap-1">
                    <p className="text-lg font-[700] text-gray-500 h-[20px]">
                      {/* Chào bạn, */}
                      {t(translate.hi)}
                    </p>
                    <p className="text-sm text-gray-500 h-[20px]">
                      {/* Đăng nhập để tham gia với chúng tôi */}
                      {t(translate.login_to_join_us)}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-[10px] mt-[20px]">
                  <button
                    onClick={() => {
                      // signIn();
                      router.push("/account/login");
                    }}
                    className=" text-center w-[124px] h-[35px] py-[8px] px-[10px] bg-white border-[1px] border-[#DDDDDD]   rounded text-sm font-[700] text-gray-500 focus:outline-none hover:bg-red-500 hover:border-red-500 hover:text-white transition-all"
                  >
                    {/* Đăng nhập */}
                    {t(translate.login_heading)}
                  </button>
                  <button
                    className=" text-center w-[124px] h-[35px] py-[8px] px-[10px] bg-white border-[1px] border-[#DDDDDD]   rounded text-sm font-[700] text-gray-500 focus:outline-none hover:bg-red-500 hover:border-red-500 hover:text-white transition-all"
                    onClick={() => {
                      router.push("/account/register");
                    }}
                  >
                    {/* Đăng ký */}
                    {t(translate.register_heading)}
                  </button>
                </div>
              </li>
              {/* <li className="px-[20px] py-[16px] cursor-pointer">
                <p className="text-base text-center font-[700] text-gray-500 capitalize mb-[10px]">
                  Hoặc Đăng nhập Với
                </p>
                <div className="flex flex-col gap-[20px] ">
                  <div className="group/item w-full flex items-center   bg-white border-[1px] border-[#DDDDDD]   rounded text-sm  text-gray-500 focus:outline-none hover:border-red-500 hover:text-red-500 transition-all">
                    <div className="w-[50px] h-full flex justify-center items-center ">
                      <GoogleFilledIcon />
                    </div>
                    <p className="py-[8px] px-[16px] border-l-[1px] border-[#DDDDDD] group-hover/item:border-red-500 transition-all">
                      Đăng Nhập Với Google
                    </p>
                  </div>
                  <div className="group/item w-full flex items-center bg-white border-[1px] border-[#DDDDDD]   rounded text-sm  text-gray-500 focus:outline-none hover:border-red-500 hover:text-red-500 transition-all">
                    <div className="w-[50px] h-full flex justify-center items-center">
                      <FacebookFilledIcon />
                    </div>
                    <p className="py-[8px] px-[16px] border-l-[1px] border-[#DDDDDD] group-hover/item:border-red-500 transition-all">
                      Đăng Nhập Với Facebook
                    </p>
                  </div>
                </div>
              </li> */}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderCredentials;
