import BaseApi from "@/common/api/BaseApi";
import { callApi, callApiV2 } from "@/lib/config/Api";
import fetchServer from "@/lib/config/fetchServer";
import {
	FilterStore,
	StoreCollectionJson,
	StoreJson,
} from "@/types/Store.type";
import Helper from "@/utils/helper";

const URL = "/stores";
export async function getListStore(filters: FilterStore = { sort_type: "ASC" }) {
	const apiParams = Helper.convertFilterToParams(filters || {});

	const res = await callApiV2<StoreCollectionJson>(
		`${URL}/public${apiParams}`,
		"get",
		{
			params: {
				...filters,
			},
		}
	);

	return res;
}

export async function getStore(id: StoreJson["code"]) {
	const res = await callApiV2<StoreJson>(`${URL}/public/${id}`, "get", {
		params: {
			show_web: 1,
		},
	});

	return res;
}
