"use client";
import CustomerConstants from "@/common/constants/customer";
import Popup from "@/components/Popup";
import { useEffect, useState } from "react";
import NTVerifyOTPNewsletter from "./NTVerifyOTPNewsLetter";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { setPopupId } from "@/redux/features/ShopSlice";

type Props = {
  contact_id: string;
  isSucess: boolean;
  setIsSucess: (f: boolean) => void;
};

const LetterPopup = ({ contact_id, isSucess, setIsSucess }: Props) => {
  const dispatch = useAppDispatch();
  const popupId = useAppSelector((state) => state.ShopReducer.popupId);
  //////////////////////////

  return (
    <Popup
      classNameCloseButton="absolute right-[20px] top-[10px]"
      classNameContent={
        "rounded-[8px] px-5 pt-[22px] pb-4 bg-white w-[436px] h-fit max-w-[90vw]"
      }
      popupId={"newsletter-popup"}
      centered={true}
      handleOnClose={() => {
        dispatch(setPopupId(""));
      }}
    >
      {popupId === "newsletter-popup" ? (
        <NTVerifyOTPNewsletter
          type={CustomerConstants.SENDER_TYPE.NEWSLETTER}
          account_id={contact_id}
          handleSubmitSuccess={() => {
            dispatch(setPopupId(""));
            setIsSucess(true);
          }}
        />
      ) : (
        <></>
      )}
    </Popup>
  );
};

export default LetterPopup;
