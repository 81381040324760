"use client";

import React from "react";

import Image from "@/components/ui/Image";
import Website from "@/common/constants/webstite";
import LinkElement from "@/components/LinkElement";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import ImageConstant from "@/common/constants/image";

export type CommunicationJson = {
	title: React.ReactNode;
	action: React.ReactNode;
	link: string;
	image: string;
};

const HeaderCommunity = ({
	communications,
}: {
	communications: CommunicationJson[];
}) => {
	const { t } = useTranslationApi(["nmagazine"]);

	return (
		<div className="group text-base text-gray-500 cursor-pointer transition-all hover:after:absolute after:bottom-[-25px] after:-right-24 after:-left-[100px] after:h-[30px] hover:after:content-[''] after:bg-[transparent] hover:after:text-[transparent] h-full flex items-center relative">
			<LinkElement
				href={Website.LINK.PAGE.NMAGAZINE}
				className="hover:text-red-500 leading-[17px] capitalize">
				{t("nmagazine")}
			</LinkElement>
			<div className="absolute hidden group-hover:block top-full right-auto -left-[100px] z-10 mt-[16px] bg-white border border-gray-300 drop-shadow-sm w-[280px] divide-y divide-gray-300">
				{communications.map((item: CommunicationJson, index: number) => (
					<LinkElement
						href={item.link}
						className="flex items-center gap-4 px-5 py-2 cursor-pointer group/item"
						key={index}>
						<Image
							width={60}
							height={60}
							src={item.image || ""}
							alt=""
							className="h-[60px] w-[60px] rounded-full overflow-hidden object-cover"
							placeholder={"blur"}
							blurDataURL={ImageConstant.BlurDataURL}
						/>
						<p>
							<span className="block capitalize text-base font-bold text-gray-500 line-clamp-1">
								{item.title}
							</span>
							<span className="block text-xs text-gray-500 group-hover/item:text-red-500 line-clamp-1">
								{item.action}
							</span>
						</p>
					</LinkElement>
				))}
			</div>
		</div>
	);
};

export default HeaderCommunity;
