import { cn } from "@/utils/utils";
import React from "react";

const ArowRightViewMoreIcon = ({ className }: { className?: string }) => {
	return (
		<>
			<span className={cn("", className)}>
				<svg
					width="16"
					height="17"
					viewBox="0 0 16 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10 8.5L10.3536 8.14645L10.7071 8.5L10.3536 8.85355L10 8.5ZM6.35355 4.14645L10.3536 8.14645L9.64645 8.85355L5.64645 4.85355L6.35355 4.14645ZM10.3536 8.85355L6.35355 12.8536L5.64645 12.1464L9.64645 8.14645L10.3536 8.85355Z"
						fill="#898889"
					/>
				</svg>
			</span>
		</>
	);
};

export default ArowRightViewMoreIcon;
