// "server-only";
import React from "react";
import { cn } from "@/utils/utils";

import LinkElement from "@/components/LinkElement";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon";

import LabelMore from "./LabelMore";
import ArowRightViewMoreIcon from "@/components/icons/ArowRightViewMoreIcon";

type Props = {
	title: string;
	title_more?: string;
	translateKey?: string;
	link: string;
	className?: string;
	classNameTitle?: string;
	classNameSubtitle?: string;
	target?: string;
	link_more?: string;
	viewMore?: boolean;
};

export default function CollectionTitle({
	title,
	title_more,
	translateKey,
	link,
	className,
	classNameTitle,
	classNameSubtitle,
	target,
	link_more,
	viewMore = true,
}: Props) {
	return (
		<LinkElement
			prefetch={false}
			href={link}
			passHref={link.includes("https")}
			target={target}
			className={cn(
				"h-[24px] md:h-[40px] leading-[24px] md:leading-[40px] flex justify-between items-center",
				className
			)}>
			<h2
				className={cn(
					"text-2xl md:text-4xl inline-block w-full text-gray-500 font-medium md:font-normal font-sans ",
					classNameTitle
				)}>
				{title}
			</h2>
			{viewMore && (
				<span
					className={cn(
						"text-base text-gray-400 flex items-center gap-1 shrink-0 leading-3",
						classNameSubtitle
					)}>
					{title_more ? title_more : <LabelMore></LabelMore>}
					<ArowRightViewMoreIcon />
				</span>
			)}
		</LinkElement>
	);
}
