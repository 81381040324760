"use client";
import React, { useState, useMemo } from "react";

import Website from "@/common/constants/webstite";
import Newsletter from "~components/newsletter/NewsLetter";
import InstagramIcon from "@/components/icons/InstagramIcon";
import PhoneFilledIcon from "@/components/icons/PhoneFilledIcon";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon";
import TiktokOutlineIcon from "@/components/icons/TiktokOutlineIcon";
import YoutubeOutlineIcon from "@/components/icons/YoutubeOutlineIcon";
import FacebookOutlineIcon from "@/components/icons/FacebookOutlineIcon";
import { useAppSelector } from "@/redux/hook";
import LinkElement from "@/components/LinkElement";
import Image from "@/components/ui/Image";
import FileModel from "@/common/models/FileModel";
import ImageConstant from "@/common/constants/image";
import { cn } from "@/utils/utils";

type Props = {
	id: string;
	className?: string;
};

export default function FooterMobile({ id, className }: Props) {
	const sidebarMobile = useAppSelector(
		(state) => state.ShopReducer.sidebarMobile
	);
	const [selected, setSelected] = useState<number>(0);
	const [active, setActive] = useState<boolean>(false);

	const handleSelect = (item: number) => {
		if (selected === item) {
			setActive(!active);
		} else {
			setSelected(item);
			setActive(true);
		}
	};

	const year = useMemo(() => {
		return new Date().getFullYear();
	}, []);

	return (
		<div
			className={cn(
				`${
					sidebarMobile ? "hidden" : "block"
				} bg-gray-500 text-gray-300 text-sm pb-[158px] flex flex-col gap-8`,
				className
			)}
			id={id}>
			{/* contact */}
			<ul className="mb-[32px]">
				<li className="px-4">
					<LinkElement
						href={Website.LINK.PAGE.TUYEN_DUNG}
						className="text-gray-300 font-bold h-[52px] flex items-center justify-between border-b-[1px] border-[#89888980] px-2 py-[18px]">
						Tuyển dụng
						<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px] text-gray-300" />
					</LinkElement>
				</li>
				<li className="px-4">
					<LinkElement
						href={Website.LINK.PAGE.CAU_HOI_THUONG_GAP}
						className="text-gray-300 font-[700] h-[52px] flex items-center justify-between border-b-[1px] border-[#89888980] px-2 py-[18px]">
						Câu hỏi thường gặp
						<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px] text-gray-300" />
					</LinkElement>
				</li>
				<li>
					{/* Accordion Head */}
					<div
						className={`px-4 ${
							active && selected === 1
								? "bg-white text-gray-500 mt-[-1px]"
								: "bg-gray-500"
						}`}
						onClick={(e) => handleSelect(1)}>
						<div
							className={`border-b-[1px] font-[700] h-[52px] flex items-center justify-between cursor-pointer px-2 py-[18px] border-[#89888980] ${
								active && selected === 1
									? "text-gray-500"
									: "text-gray-300 cursor-pointer"
							}`}>
							namperfume
							<ChevronRightIcon
								className={`w-[14px] h-[14px] min-w-[14px] ${
									active && selected === 1
										? "text-gray-500 -rotate-90"
										: "text-gray-300 rotate-90"
								} `}
							/>
						</div>
					</div>
					{/* Accordion Content */}
					<div
						className={`transition-height linear duration-700 bg-white overflow-hidden ${
							active && selected === 1 ? "h-[208px]" : "h-0"
						}`}>
						<LinkElement
							href={Website.LINK.PAGE.LIEN_HE}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							Liên hệ
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.PAGE.ABOUT_US}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							Store-Hour
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.PAGE.CHINH_SACH_BAO_MAT}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							Chính sách bảo mật
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.PAGE.NMAGAZINE}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							nmagazine
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
					</div>
				</li>
				<li>
					{/* Accordion Head */}
					<div
						className={`px-4 ${
							active && selected === 2
								? "bg-white text-gray-500 mt-[-1px]"
								: "bg-gray-500 "
						}`}
						onClick={(e) => handleSelect(2)}>
						<div
							className={`border-b-[1px] font-[700] h-[52px] flex items-center justify-between cursor-pointer px-2 py-[18px] border-[#89888980] ${
								active && selected === 2
									? "text-gray-500"
									: "text-gray-300 cursor-pointer"
							}`}>
							Hỗ trợ online
							<ChevronRightIcon
								className={`w-[14px] h-[14px] min-w-[14px] ${
									active && selected === 2
										? "text-gray-500 -rotate-90"
										: "text-gray-300 rotate-90"
								} `}
							/>
						</div>
					</div>
					{/* Accordion Content */}
					<div
						className={`transition-height linear duration-700 bg-white overflow-hidden ${
							active && selected === 2 ? "h-[312px]" : "h-0"
						}`}>
						<LinkElement
							href={Website.LINK.PAGE.HUONG_DAN_DAT_HANG}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							Hướng dẫn đặt hàng
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.PAGE.CHINH_SACH_GIA_CA}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							Chính sách giá cả
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.PAGE.THEO_DOI_DON_HANG}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							Theo dõi đơn hàng
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.PAGE.PHUONG_THUC_VAN_CHUYEN}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							Phương thức vận chuyển
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.PAGE.PHUONG_THUC_THANH_TOAN}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							Phương thức thanh toán
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.PAGE.CHINH_SACH_DOI_TRA}
							className="h-[52px] flex items-center justify-between border-b-[1px] border-gray-300 pl-[24px] pr-2 hover:text-shop-red transition-all text-gray-500 mx-[16px] py-[18px]">
							Chính sách đổi trả
							<ChevronRightIcon className="w-[14px] h-[14px] min-w-[14px]" />
						</LinkElement>
					</div>
				</li>
			</ul>
			{/* social */}
			<ul className="flex flex-wrap justify-between items-center gap-[10px] px-4 mb-[32px]">
				<li>
					<LinkElement
						href={Website.LINK.MEDIA.INSTAGRAM}
						target="_blank"
						className="flex items-center gap-[3px]">
						<InstagramIcon className="w-[16px] h-[16px] min-w-[16px]" />
						Instagram
					</LinkElement>
				</li>
				<li>
					<LinkElement
						href={Website.LINK.MEDIA.FACEBOOK}
						target="_blank"
						className="flex items-center gap-[3px]">
						<FacebookOutlineIcon className="w-[16px] h-[16px] min-w-[16px]" />
						Facebook
					</LinkElement>
				</li>
				<li>
					<LinkElement
						href={Website.LINK.MEDIA.YOUTUBE}
						target="_blank"
						className="flex items-center gap-[3px]">
						<YoutubeOutlineIcon className="w-[16px] h-[16px] min-w-[16px]" />
						Youtube
					</LinkElement>
				</li>
				<li>
					<LinkElement
						href={Website.LINK.MEDIA.TIKTOK}
						target="_blank"
						className="flex items-center gap-[3px]">
						<TiktokOutlineIcon className="w-[16px] h-[16px] min-w-[16px]" />
						Tiktok
					</LinkElement>
				</li>
			</ul>
			{/* newsletter */}
			<div className="px-4">
				<Newsletter />
				<div className="uppercase text-sm text-gray-300 flex items-center gap-[4px] mb-[32px]">
					<p>gọi đặt mua:</p>
					<span className="w-[16px] h-[16px] flex justify-center items-center">
						<PhoneFilledIcon />
					</span>
					<LinkElement
						className="hover:underline"
						target="_blank"
						href={Website.LINK.MEDIA.CONTACT}>
						1900 0129 (9:00 - 21:00)
					</LinkElement>
				</div>
			</div>
			{/* slogan */}
			<div className="px-4 mb-8">
				<p className="text-3xl text-gray-300 mb-4 h-[58px] leading-[29px] font-noto">
					Nơi mùi hương là bạn
					<br />
					đồng hành
				</p>
				<LinkElement href={Website.TAX} target="_blank">
					{/* <MOIATIcon className="w-[101px] h-[38px]" /> */}
					<Image
						width={106}
						height={40}
						className="w-[106px] h-[40px]"
						src={
							"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/April/17242-223071712719093-1712719093.png"
						}
						alt="motaicon"
						loading="lazy"
						placeholder={"blur"}
						blurDataURL={ImageConstant.BlurDataURL}
					/>
				</LinkElement>
			</div>
			{/* copyright */}
			<div className="text-sm leading-[15px] px-4 font-sm text-gray-400">
				<p>
					Copyright 2013-{year} © namperfume. All Rights Reserved. Cty TNHH
					namperfume <br />
					GPKD: 0316901314. Ngày cấp: 09/06/2021. Nơi cấp: Sở KH&DT TP.HCM
					<br />
					Địa chỉ: Tầng 7, 19A Cộng Hòa, Tòa Nhà Scetpa, Phường 12, Quận Tân
					Bình, TP. Hồ Chí Minh
				</p>
			</div>
		</div>
	);
}
