"use client";

import React from "react";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

import { StoryJson } from "@/types/Story.type";
import Website from "@/common/constants/webstite";

import Image from "@/components/ui/Image";
import LinkElement from "@/components/LinkElement";
import DataEmpty from "~components/empty/DataEmpty";
import LoadingTinySkeleton from "~components/skeleton/LoadingTinySkeleton";
import ImageConstant from "@/common/constants/image";

type Props = {
  data: { new: StoryJson[]; recent: StoryJson[] };
  errors: { new: string[]; recent: string[] };
  loading: boolean;
};

const NotificationList = ({
  data,
  errors,
  type = "new",
}: {
  data: StoryJson[];
  errors: string[];
  type?: "recent" | "new";
}) => {
  return (
    <>
      {errors.length > 0 ? (
        <span className="text-red-500">{errors[0]}</span>
      ) : (
        <>
          {data.length > 0 ? (
            <>
              {data.map((item: StoryJson, index: number) => (
                <LinkElement
                  key={index}
                  href={`${Website.LINK.PAGE.BLOG}/${
                    item?.collections[0]?.handle || "collection"
                  }/${item.handle}`}
                  className={`flex justify-between items-center gap-3 ${
                    type === "new" ? "bg-gray-200" : "bg-white"
                  } text-gray-500`}
                >
                  <Image
                    width={500}
                    height={500}
                    src={item.images?.[0]?.url || "/"}
                    alt="photo"
                    className="w-[100px] h-[100px] object-cover"
                    placeholder={"blur"}
                    blurDataURL={ImageConstant.BlurDataURL}
                  />
                  <div className="flex-1 pr-[18px]">
                    <h3
                      className={`text-base ${
                        type === "new" ? "font-bold" : "font-medium"
                      } leading-[22px] line-clamp-2`}
                    >
                      {item.title}
                    </h3>
                  </div>
                </LinkElement>
              ))}
            </>
          ) : (
            <DataEmpty />
          )}
        </>
      )}
    </>
  );
};

const HeaderTinyNotification = ({ data, errors, loading }: Props) => {
  const { t } = useTranslationApi(["recent_notification", "new_notification"]);
  return (
    <div className="fixed top-[70px] left-0 right-0 bottom-[55px] overflow-y-scroll bg-white p-4 pr-0 z-50">
      {loading ? (
        <LoadingTinySkeleton />
      ) : (
        <div className="flex flex-col gap-[10px] max-h-[80vh] overflow-y-scroll scrollbarCustom pr-4">
          <h2 className="text-gray-500 text-base font-medium">
            {/* Thông báo mới */}
            {t("new_notification")}
          </h2>
          <div className="flex flex-col gap-[2px]">
            <NotificationList data={data.new} errors={errors.new} />
          </div>
          <h2 className="text-gray-400 text-base font-medium">
            {/* Thông báo gần đây */}
            {t("recent_notification")}
          </h2>
          <div className="flex flex-col gap-[2px]">
            <NotificationList
              data={data.recent}
              errors={errors.recent}
              type="recent"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderTinyNotification;
