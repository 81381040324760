import BaseApi from "@/common/api/BaseApi";
import { callApiV2 } from "@/lib/config/Api";
import {
  ContactFeedbackPostJson,
  ContactSupportPostJson,
  NewsLetterPostJson,
  SenderNewsLetterJson,
  SenderNewsLetterPostJson,
  VerifyNewsLetterPostJson,
} from "@/types/NewsLetter.type";

//news letter
export async function postNewsLetter(data: NewsLetterPostJson) {
  let postData = {
    ...data,
  };
  const response = await callApiV2<any>(
    "/customers/public/newsletter",
    "post",
    {
      data: postData,
    }
  );

  return response;
}

export async function senderNewsLetter(data: SenderNewsLetterPostJson) {
  let postData = {
    ...data,
  };
  const response = await callApiV2<SenderNewsLetterJson>(
    "/customers/public/sendernewsletter",
    "post",
    { data: postData }
  );

  return response;
}

export async function verifyNewsLetter(data: VerifyNewsLetterPostJson) {
  let postData = {
    ...data,
  };
  const response = await callApiV2<boolean>(
    "/customers/public/verifynewsletter",
    "post",
    {
      data: postData,
    }
  );
  return response;
}

//contact
export async function postContact(data: ContactSupportPostJson) {
  let postData = {
    ...data,
  };
  const res = await callApiV2<any>(
    "/customers/public/contact/support",
    "post",
    {
      data: postData,
    }
  );

  return res;
}

//feedback
export async function postFeedback(data: ContactFeedbackPostJson) {
  let postData = {
    ...data,
  };
  const res = await callApiV2<any>(
    "/customers/public/contact/feedback",
    "post",
    {
      data: postData,
    }
  );

  return res;
}

