"use client";

import {
  getListProduct,
  getProductsRecentViewToLocal,
} from "@/lib/api/shop/products";
import { useEffect, useState } from "react";
import { useAppSelector } from "@/redux/hook";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { addListProductRecentView } from "@/redux/features/ShopSlice";

import Website from "@/common/constants/webstite";
import { ProductJson } from "@/types/Product.type";
import ProductConstants from "@/common/constants/product";

import PerfumeIcon from "@/components/icons/PerfumeIcon";
import LoadingSkeleton from "~components/skeleton/LoadingSkeleton";
import CollectionTitle from "~components/collection/children/CollectionTitle";
import ProductRecentList from "~components/collection/children/ProductRecentList";
import CollectionLoading from "~components/collection/children/CollectionLoading";
import useStoreId from "@/lib/hooks/useStoreId";

export default function ProductsRecentView({
  store_id: store_id_props,
  type,
}: {
  store_id?: number | string;
  type?: "slider" | "grid";
}) {
  const { t } = useTranslationApi([
    "recently_viewed",
    "recently_viewed_empty",
    "product",
  ]);

  const { data: store_id } = useStoreId();

  const [products, setProducts] = useState<ProductJson[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const listProductRecentViewRedux = useAppSelector(
    (state) => state.ShopReducer.listProductRecentView
  );

  async function initData() {
    if (!store_id) return;
    if (listProductRecentViewRedux.length > 0) {
      setProducts(listProductRecentViewRedux);
      return;
    }
    setLoading(true);

    const idsProduct = getProductsRecentViewToLocal();
    if (!idsProduct.length) return;
    try {
      const res = await getListProduct({
        page: 1,
        limit: ProductConstants.PRODUCT_RECENT_VIEW.LIMIT_NUMBER_SAVE,
        list_product_id: idsProduct.join(","),
        store_id: store_id,
      });
      const items = res.data.items;
      const sortedProducts = idsProduct
        .map((id) => items.find((product) => product.id === id))
        .filter((item) => item !== undefined) as ProductJson[];

      setProducts(sortedProducts);
      addListProductRecentView(sortedProducts);
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    initData();
  }, [listProductRecentViewRedux, store_id]);

  if (!products.length) {
    return (
      <>
        {type === "grid" && (
          <>
            <Title
              title={t("recently_viewed")}
              totalProduct={products.length}
            />
            <div className="mt-8 mb-16 md:mt-12 md:mb-20 flex flex-col items-center justify-center gap-2 md:gap-5">
              <PerfumeIcon className="w-[64px] h-[64px] md:w-[80px] md:h-[80px] text-gray-300" />
              <p className="text-sm text-center">
                {/* Bạn chưa xem sản phẩm nào */}
                {t("recently_viewed_empty")}
              </p>
            </div>
          </>
        )}
      </>
    );
  }
  return (
    <div className="w-full">
      {type === "grid" ? (
        <Title title={t("recently_viewed")} totalProduct={products.length} />
      ) : (
        <CollectionTitle
          viewMore={false}
          title={t("recently_viewed")}
          link={Website.LINK.PAGE.XEM_GAN_DAY}
          className="px-4 md:px-0 mb-3 md:mb-8"
          classNameTitle="font-sans"
        />
      )}
      {loading ? (
        <>{type === "grid" ? <LoadingSkeleton /> : <CollectionLoading />}</>
      ) : (
        <>
          {products.length > 0 && (
            <ProductRecentList data={products} type={type} />
          )}
        </>
      )}
    </div>
  );
}

function Title({
  title,
  totalProduct,
}: {
  title: string;
  totalProduct: number;
}) {
  return (
    <div className="w-full text-center md:text-left text-gray-500">
      <p className="mt-[24px] mb-[2px] text-base font-bold md:text-3xl md:font-normal capitalize">
        {/* xem gần đây */}
        {title}
      </p>
      <p className="mb-[24px] text-xs md:text-base">
        {totalProduct > 0 && "(" + totalProduct + " sản phẩm)"}
      </p>
    </div>
  );
}
