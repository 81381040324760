"use client";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import useTranslationAPI from "@/lib/hooks/useTranslationApi";

import "swiper/css";
import "swiper/css/effect-fade";

export default function HeaderTopLeft() {
	const { t } = useTranslationAPI(["header_top_left_1", "header_top_left_2"]);
	
	return (
		<Swiper
			spaceBetween={30}
			className="w-[100%] text-center text-white text-sm md:w-[50%] md:text-gray-500 md:text-base md:text-left"
			slidesPerView={2}
			effect="fade"
			// allowSlideNext={false}
			allowTouchMove={false}
			// allowSlidePrev={false}
			// enabled={false}
			loop
			autoplay={{
				delay: 2000,
				disableOnInteraction: false,
			}}
			fadeEffect={{ crossFade: true }}
			modules={[Autoplay, EffectFade]}>
			<SwiperSlide>
				<p className="text-sm lg:text-base leading-[16px] md:leading-[17px] w-full h-full text-center flex justify-center md:justify-start items-center md:text-left">
					{t("header_top_left_1")}
				</p>
			</SwiperSlide>
			<SwiperSlide>
				<p className="text-sm lg:text-base leading-[16px] md:leading-[17px] w-full h-full text-center flex justify-center md:justify-start items-center md:text-left">
					{t("header_top_left_2")}
				</p>
			</SwiperSlide>
		</Swiper>
	);
}
