"use client";
import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import CardSkeleton from "~components/card/children/CardSkeleton";

const CollectionLoading = () => {
	return (
		<div className="w-full">
			<div className="px-[15px] md:px-0 flex justify-between items-center mb-[18px]">
				<div className="h-[20px] bg-gray-300 rounded-full w-[100px]" />
				<div className="h-[10px] bg-gray-300 rounded-full w-[50px]" />
			</div>

			<div className="hidden lg:grid grid-cols-6 gap-[20px] px-[15px]">
				{Array(6)
					.fill(0)
					.map((item, index) => {
						return <CardSkeleton key={index} />;
					})}
			</div>
			<div className="hidden md:grid lg:hidden grid-cols-4 gap-[20px] px-[15px]">
				{Array(4)
					.fill(0)
					.map((item, index) => {
						return <CardSkeleton key={index} />;
					})}
			</div>
			<div className="grid md:hidden grid-cols-2">
				{Array(2)
					.fill(0)
					.map((item, index) => {
						return <CardSkeleton key={index} />;
					})}
			</div>
		</div>
	);
};

export default CollectionLoading;
