import PhotoIcon from "@/components/icons/PhotoIcon";

const CardSkeleton = () => {
	return (
		<div className="animate-pulse flex flex-col gap-6 items-center border border-gray-200 md:border-none p-6 md:p-0 h-[342px]">
			<div className="flex items-center justify-center w-full md:h-[170px] h-[140px] bg-gray-300">
				<PhotoIcon />
			</div>
			<div className="w-full flex flex-col gap-1">
				<div className="h-3 bg-gray-300 w-[50%]" />
				<div className="h-2 bg-gray-300" />
				<div className="h-2 bg-gray-300 w-[70%] mt-1" />
			</div>
		</div>
	);
};

export default CardSkeleton;
