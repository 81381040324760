"use client";
import { useState, useEffect } from "react";
import Image, { PropsImage } from "./ui/Image";

const DelayedImage = ({
	delay = 10,
	...props
}: PropsImage & {
	delay?: number;
}) => {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, delay);

		// Cleanup the timer on component unmount
		return () => clearTimeout(timer);
	}, [delay]);

	return (
		<>
			{isLoading ? (
				<div
					style={{
						width: props.width,
						height: props.height,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: "#f0f0f0",
					}}></div>
			) : (
				<Image {...props} />
			)}
		</>
	);
};

export default DelayedImage;
