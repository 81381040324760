"use client";

import React from "react";
import { cn } from "@/utils/utils";
import { LoadingIcon } from "@/components/icons";

type Props = {
  className?: string;
  classNameIcon?: string;
};

function LoadingSkeleton({ className, classNameIcon }: Props) {
  return (
    <div
      className={cn(
        "w-full min-h-[50vh] flex items-center justify-center gap-2",
        className
      )}
    >
      <LoadingIcon className={classNameIcon} />
      {/* <span className="text-base text-gray-500">{t("loading_page")}</span> */}
    </div>
  );
}

export default LoadingSkeleton;
