"use client";
import CustomerConstants from "@/common/constants/customer";
import Popup from "@/components/Popup";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import React, { useEffect, useState } from "react";

type Props = {
  errors: string[];
  handleOnClose: () => void;
};
const translate = {
  newsletter_error_contactid_exist_1: "newsletter_error_contactid_exist_1",
  newsletter_error_contactid_exist_2: "newsletter_error_contactid_exist_2",
  newsletter_error_other: "newsletter_error_other",
  notification: "notification",
};
const LetterErrorPopup = ({ errors, handleOnClose }: Props) => {
  const [desc, setDesc] = useState<any>(<></>);

  

  const { t } = useTranslationApi(Object.values(translate));

  const fetchErrorsDescription = (errors: string) => {
    switch (errors) {
      case CustomerConstants.ERRORS.ERROR_CONTACTID_EXIST:
        setDesc(
          <>
            <p className="text-sm">
              {t(translate.newsletter_error_contactid_exist_1)}
            </p>
            <p className="text-sm">
              {t(translate.newsletter_error_contactid_exist_2)}
            </p>
          </>
        );
        break;
      default:
        setDesc(
          <>
            <p className="text-sm">{t(translate.newsletter_error_other)}</p>
          </>
        );
        break;
    }
  };

  useEffect(() => {
    if (errors.length > 0) {
      fetchErrorsDescription(errors[0]);
    }
  }, [errors]);

  return (
    <Popup
      classNameCloseButton="absolute right-[20px] top-[10px]"
      classNameContent={
        "rounded-[8px] px-[47px] py-[32px] bg-white w-[330px] h-fit"
      }
      popupId={"newsletter-error-popup"}
      centered={true}
      handleOnClose={handleOnClose}
    >
      <div className="text-base text-center text-gray-500">
        <p className="font-bold text-red-500 ">{t(translate.notification)}</p>
        <div className="mt-4">{desc}</div>
      </div>
    </Popup>
  );
};

export default LetterErrorPopup;
