"use client";
import React, { useEffect, useState } from "react";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import PopupV2 from "./PopupV2";

type Props = {
	classNameTransitionBg?: string;
	classNameTransitionContent?: string;
	classNameCloseButton?: string;
	classNameContent?: string;
	children: React.ReactNode;
	popupId: string;
	centered?: boolean;
	unmountOnExit?: boolean;
	handleOnClose?: () => void;
	handleCallbackClose?: () => void;
	closeOnMark?: boolean;
	isDrawerMobile?: boolean;
	title?: string;
	animate?: "tran" | "fade" | "tran-up";
};

const Popup = ({
	//   classNameTransitionBg = "modal-bg",
	//   classNameTransitionContent = "modal-content",
	//   classNameCloseButton,
	classNameContent,
	//   classNameContainer,
	children,
	popupId,
	//   centered = false,
	//   unmountOnExit = false,
	handleOnClose,
	//   handleCallbackClose,
	closeOnMark = true,
	isDrawerMobile = false,
	title,
	animate,
}: Props) => {
	const popupIdGlobal = useSelector(
		(state: RootState) => state.ShopReducer.popupId
	);

	const [show, setShow] = useState<boolean>(false);

	useEffect(() => {
		if (popupIdGlobal === popupId) {
			setShow((prev) => popupIdGlobal === popupId);
		} else {
			setShow((prev) => false);
		}
	}, [popupIdGlobal]);

	return (
		<PopupV2
			title={title}
			open={show}
			onOpenChange={() => {
				handleOnClose?.();
			}}
			classNameContent={classNameContent}
			closeOnMark={closeOnMark}
			isDrawerMobile={isDrawerMobile}
			animate={animate}>
			{children}
		</PopupV2>
	);
};

export default Popup;
