import Helper from "@/utils/helper";
import { callApiV2 } from "@/lib/config/Api";

import { CollectionJson } from "@/types/Collection.type";
import {
	FilterStoryCollection,
	StoryCollectionCollectionJson,
	StoryCollectionJson,
} from "@/types/StoryCollection.type";

// export async function getDetailCollection(id: number | string) {
// 	const res = await callApiV2<CollectionJson>(
// 		`/productcollections/public/${id}`,
// 		"get"
// 	);
// 	return res;
// }

export async function getDetailCollectionV2(id: number | string) {
	const res = await callApiV2<CollectionJson>(
		`/productcollections/public/${id}`,
		"get"
	);
	return res;
}

export async function getListStoryCollection(filter: FilterStoryCollection) {
	const params = Helper.convertFilterToParams(filter);
	const res = await callApiV2<StoryCollectionCollectionJson>(
		`/storycollections/public${params}`,
		"get"
	);
	return res;
}

export async function getDetailStoryCollection(id: number | string) {
	const res = await callApiV2<StoryCollectionJson>(
		`/storycollections/public/${id}`,
		"get"
	);
	return res;
}
