"use client";
import { cn } from "@/utils/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useMemo } from "react";
import LinkElement from "../LinkElement";

type Props = {
	children: React.ReactNode;
	href: string;
	className?: string;
	activeClassName?: string;
	absolute?: boolean;
	style?: React.CSSProperties;
	hide_on_active?: boolean;
};
const NavLink = ({
	children,
	href,
	className,
	activeClassName,
	absolute,
	style,
	hide_on_active,
	...props
}: Props) => {
	const pathname = usePathname();
	const isActive = useMemo(() => {
		if (absolute) {
			if (pathname === href) {
				return true;
			}
		} else {
			if (pathname.startsWith(href)) {
				return true;
			}
		}

		return false;
	}, [pathname, href]);
	return (
		<LinkElement
			style={style}
			className={cn(
				className,
				isActive &&
					(hide_on_active
						? "hidden"
						: activeClassName || "text-red-500 underline")
			)}
			href={href}>
			{children}
		</LinkElement>
	);
};

export default NavLink;
