import { createSlice } from "@reduxjs/toolkit";
import { ConfigDealthom, PromotionJson } from "@/types/Promotion.type";
import { RootState } from "../store";

type InitialState = {
	promotion: PromotionJson | null;
	promotionCanUses: PromotionJson[];
	promotionCouponOnItems: PromotionJson[];
	settingDealthom: ConfigDealthom[] | null;
};
const initialState: InitialState = {
	promotion: null,
	promotionCanUses: [],
	promotionCouponOnItems: [],
	settingDealthom: null,
};

const PromotionSlice = createSlice({
	initialState: initialState,
	name: "promotion",
	reducers: {
		setPromotion: (state, action) => {
			state.promotion = action.payload;
		},
		setPromotionCanUses: (state, action) => {
			state.promotionCanUses = action.payload;
		},
		setSettingDealthom: (state, { payload }: { payload: ConfigDealthom[] }) => {
			state.settingDealthom = payload;
		},
		setPromotionCouponOnItems: (
			state,
			{ payload }: { payload: PromotionJson[] }
		) => {
			state.promotionCouponOnItems = payload;
		},
	},
});

export const {
	setPromotion,
	setPromotionCanUses,
	setSettingDealthom,
	setPromotionCouponOnItems,
} = PromotionSlice.actions;
export const getPromotion = (state: RootState) => {
	return state.PromotionReducer.promotion;
};
export const getSettingDealthom = (state: RootState) => {
	return state.PromotionReducer.settingDealthom;
};
export const getPromotionCouponOnItems = (state: RootState) => {
	return state.PromotionReducer.promotionCouponOnItems;
};
export default PromotionSlice.reducer;
