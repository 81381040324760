"use client"
import Website from '@/common/constants/webstite'
import { PhoneFilledIcon } from '@/components/icons'
import LinkElement from '@/components/LinkElement'
import useTranslationApi from '@/lib/hooks/useTranslationApi'
import React from 'react'
import Newsletter from '~components/newsletter/NewsLetter'

export default function FooterNewsletter() {

  const { t } = useTranslationApi([
    "footer_slogan_1",
    "footer_slogan_2",
    "footer_call_buy",
  ]);
  return (
    <div className="min-w-[275px]">
      <p className="text-4xl text-gray-300 h-[82px] leading-[40px] mb-14 font-noto">
        {t("footer_slogan_1")} <br />
        {t("footer_slogan_2")}
      </p>
      <div>
        <Newsletter />
        <div className="uppercase text-sm text-gray-300 flex items-center gap-1 mt-4">
          <p>        {t("footer_call_buy")}
          </p>
          <PhoneFilledIcon className="text-gray-300 w-6 h-6 min-w-6 p-1" />
          <LinkElement
            className="hover:underline underline-offset-1"
            target="_blank"
            href={Website.LINK.MEDIA.CONTACT}
          >
            1900 0129 (9:00 - 21:00)
          </LinkElement>
        </div>
      </div>
    </div>
  )
}
