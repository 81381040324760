import React from "react";
import { cn } from "@/utils/utils";
function PerfumeIcon({ className }: { className?: string }) {
  return (
    <span className={cn("block w-4 h-4 text-gray-500", className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"100%"}
        height={"100%"}
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M11.2381 6.43824C11.4484 6.43824 11.619 6.26758 11.619 6.05729V5.67634H12.4533C12.7878 6.56701 14.2811 7.20015 16.1905 7.20015C18.3627 7.20015 20 6.3811 20 5.29539C20 4.20967 18.3627 3.39062 16.1905 3.39062C14.2811 3.39062 12.787 4.02377 12.4533 4.91443H11.619V3.77158C11.619 3.56129 11.4484 3.39062 11.2381 3.39062H9.71429C9.504 3.39062 9.33333 3.56129 9.33333 3.77158V6.05729C9.33333 6.26758 9.504 6.43824 9.71429 6.43824H10.0952V7.20015H9.71429C9.08419 7.20015 8.57143 7.71291 8.57143 8.34301V9.01043C5.92686 9.82567 4 12.292 4 15.2001C4 18.7712 6.90514 21.6763 10.4762 21.6763C14.0472 21.6763 16.9524 18.7712 16.9524 15.2001C16.9524 12.292 15.0255 9.82567 12.381 9.01043V8.34301C12.381 7.71291 11.8682 7.20015 11.2381 7.20015H10.8571V6.43824H11.2381ZM16.1905 4.15253C18.051 4.15253 19.2381 4.8291 19.2381 5.29539C19.2381 5.76167 18.051 6.43824 16.1905 6.43824C14.3299 6.43824 13.1429 5.76167 13.1429 5.29539C13.1429 4.8291 14.3299 4.15253 16.1905 4.15253ZM16.1905 15.2001C16.1905 18.3514 13.6274 20.9144 10.4762 20.9144C7.32495 20.9144 4.7619 18.3514 4.7619 15.2001C4.7619 12.0489 7.32495 9.48586 10.4762 9.48586C13.6274 9.48586 16.1905 12.0489 16.1905 15.2001ZM11.2381 7.96205C11.4484 7.96205 11.619 8.13272 11.619 8.34301V8.83139C11.2472 8.76434 10.867 8.72396 10.4762 8.72396C10.0853 8.72396 9.70514 8.76434 9.33333 8.83139V8.34301C9.33333 8.13272 9.504 7.96205 9.71429 7.96205H11.2381ZM10.0952 5.67634V4.15253H10.8571V5.67634H10.0952ZM6.28571 15.2001C6.28571 14.9899 6.11505 14.8192 5.90476 14.8192C5.69448 14.8192 5.52381 14.9899 5.52381 15.2001C5.52381 17.9308 7.74552 20.1525 10.4762 20.1525C10.6865 20.1525 10.8571 19.9819 10.8571 19.7716C10.8571 19.5613 10.6865 19.3906 10.4762 19.3906C8.16533 19.3906 6.28571 17.511 6.28571 15.2001Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

export default PerfumeIcon;
