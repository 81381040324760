"use client";

import { LanguageSetting } from "@/common/constants/language";
import {
	CheckTagIcon,
	UnitedStateFlagIcon,
	VietnamFlagIcon,
} from "@/components/icons";
import LinkElement from "@/components/LinkElement";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { useAppSelector } from "@/redux/hook";

export default function FooterLanguage() {
	const language = useAppSelector((state) => state.ShopReducer.language);
	const { t } = useTranslationApi([
		"footer_lang_vi",
		"footer_lang_en",
		"language_footer_title",
	]);
	const linkVI = process.env.REACT_APP_DOMAIN!;
	return (
		<div>
			<p className="mb-6 h-[17px] text-gray-300 font-[700] text-base">
				{t("language_footer_title")}
			</p>
			<div className="flex flex-col gap-[12px]">
				{LanguageSetting.map((lng) => {
					return (
						<LinkElement
							href={lng.lang === "vi" ? linkVI : "https://en.namperfume.net"}
							passHref={true}
							target="_self"
							onClick={() => {}}
							key={lng.lang}
							className="flex items-center gap-[6px]">
							{lng.lang === "vi" ? (
								<VietnamFlagIcon className="" />
							) : (
								<UnitedStateFlagIcon className="" />
							)}

							<p className="capitalize flex items-center gap-[6px]">
								{t(`footer_lang_${lng.lang}`)}{" "}
								{language.lang === lng.lang && (
									<CheckTagIcon className="text-white" />
								)}
							</p>
						</LinkElement>
					);
				})}
			</div>
		</div>
	);
}
