"use client";
import React, { useId } from "react";
import Slider from "@/components/Slider";
import { ProductJson } from "@/types/Product.type";
import ProductCard from "~components/product/ProductCard";
import LoadingSkeleton from "~components/skeleton/LoadingSkeleton";
import Carousel from "@/components/Carousel";
import { SlideNextIcon, SlidePrevIcon } from "@/components/icons";
import { Device } from "@/types/Shop.type";
import { useAppSelector } from "@/redux/hook";

type Props = {
	data: ProductJson[];
	type?: "slider" | "grid";
	isLoading?: boolean;
};

export default function ProductRecentList({
	data,
	type = "slider",
	isLoading = false,
}: Props) {
	const device = useAppSelector((state) => state.ShopReducer.device);

	const handleRender = (item: ProductJson) => {
		return <ProductCard key={item.id} data={item} />;
	};
	return (
		<div className="h-full w-full">
			{type === "grid" ? (
				<div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
					{data.map(
						(item: ProductJson) =>
							item.full_name &&
							item.full_name.length > 0 && (
								<ProductCard data={item} key={item.id} />
							)
					)}
				</div>
			) : (
				<>
					{/* <Slider
          id={useId()}
          dataSource={data}
          initialSlide={0}
          isloading={false}
          itemRender={handleRender}
          navigation={true}
          speed={1000}
          autoplay={false}
          loop={true}
          slidesPerGroup={1}
          slidesPerView={1}
          allowSlideNext={true}
          allowSlidePrev={true}
          freeMode={true}
          allowTouchMove={true}
          centeredSlidesDesktop={false}
          centeredSlidesMobile={true}
          classButtonSlide="hidden md:block"
          styleSlide={{
            height: "auto",
          }}
          styleButton_next={{ right: "-30px" }}
          styleButton_prev={{ left: "-30px" }}
          responsive={{
            xxs: { slidesPerView: 1.8, spaceBetween: 0 },
            xs: { slidesPerView: 2, spaceBetween: 0 },
            sm: { slidesPerView: 2, spaceBetween: 0 },
            md: { slidesPerView: 4, spaceBetween: 0 },
            lg: { slidesPerView: 6, spaceBetween: 0 },
            xl: { slidesPerView: 6, spaceBetween: 0 },
          }}
        /> */}
					<Carousel
						device={device}
						data={data}
						itemSlider={handleRender}
						breakpoints={{
							0: { slidesPerView: 2, spaceBetween: 0 },
							375: { slidesPerView: 2, spaceBetween: 0 },
							414: { slidesPerView: 2, spaceBetween: 0 },
							640: { slidesPerView: 2, spaceBetween: 0 },
							768: { slidesPerView: 4, spaceBetween: 0 },
							1024: { slidesPerView: 6, spaceBetween: 0 },
							1200: { slidesPerView: 6, spaceBetween: 0 },
						}}
						loop={true}
						centeredSlides={{
							desktop: false,
							mobile: true,
						}}
						navigation={true}
						navigationButton={{
							iconNext: <SlideNextIcon className="mt-[-60px]" />,
							iconPrev: <SlidePrevIcon className="mt-[-60px]" />,
						}}
					/>
				</>
			)}
		</div>
	);
}
