"use client";
import React from "react";
import useWishlist from "@/lib/hooks/useWishlist";
import WishIcon from "@/components/icons/WishIcon";
import LinkElement from "@/components/LinkElement";
import useWishlistV2 from "@/lib/hooks/useWishlistV2";

const HeaderWishlist = () => {
	const { wishlist } = useWishlistV2();

	const wishlistShow = wishlist.filter((item) => item.item_type == 1);

	return (
		<LinkElement
			aria-label="go to wishlist"
			href={"/pages/wishlist"}
			type="button"
			className="relative inline-flex items-center h-[24px] flex-1 text-center">
			<WishIcon />
			{wishlistShow && wishlistShow.length > 0 && (
				<span className="absolute flex justify-center items-center w-[15px] h-[15px] leading-[15px] text-[8px] font-[700] text-white bg-red-500 rounded-full bottom-0 right-[-5px]">
					{wishlistShow.length}
				</span>
			)}
		</LinkElement>
	);
};

export default HeaderWishlist;
