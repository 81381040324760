import React from "react";
import { ProductJson } from "@/types/Product.type";
import Link from "next/link";
import Money from "@/components/ui/Money";
import Image from "@/components/ui/Image";
import FileModel from "@/common/models/FileModel";
import ImageConstant from "@/common/constants/image";
export default function ProductItem({ product }: { product: ProductJson }) {
  return (
    <Link
      href={`/products/${product.handle}`}
      className="p-2 flex gap-2 hover:bg-[#f4f4f4]"
      key={product.id}
    >
      <Image
        alt="img"
        width={40}
        height={40}
        src={
          product.images?.[0]?.url
            ? FileModel.getThumbnailFromUrl(
                product.images?.[0]?.url || "",
                45,
                45,
                "resize"
              )
            : ""
        }
        placeholder={"blur"}
        blurDataURL={ImageConstant.BlurDataURL}
        className=" w-10 h-10 flex-shrink-0"
      />
      <div className="flex flex-col">
        <h6 className="text-base mb-1 hover:text-red-500 transition cursor-pointer line-clamp-1">
          {product.full_name}
        </h6>
        <div className="flex items-center text-sm font-semibold">
          {product.compare_at_price ? (
            <>
              <p className="text-red-500 mr-2">
                <Money value={product.price} />
              </p>
              <p className="text-gray-500 mr-2 line-through">
                <Money value={product.compare_at_price} />
              </p>
            </>
          ) : (
            <p className="text-red-500 mr-2">
              <Money value={product.price} />
            </p>
          )}
        </div>
      </div>
    </Link>
  );
}
