"use client";
import { useEffect, useState } from "react";
import { getListNotification } from "../api/shop/stories";
import { useQueries } from "@tanstack/react-query";
import { StoryJson } from "@/types/Story.type";

const useNotification = () => {


	const [notifications, setNotifications] = useState<{ new: StoryJson[]; recent: StoryJson[] }>({
		new: [],
		recent: []
	})
	const [errors, setErrors] = useState<{ new: string[]; recent: string[] }>({
		new: [],
		recent: [],
	});
	const [loading, setLoading] = useState<boolean>(false);



	const datasQuery = [{
		tags: "new-notification",
		collection_id: "thong-bao-moi",
	}, {
		tags: "recent-notification",
		collection_id: "thong-bao-gan-day",
	}]


	const datas = useQueries({
		queries: datasQuery.map(filter => {
			return {
				queryKey: ["variant", filter.collection_id],
				queryFn: async () => {
					const { data: res } = await getListNotification(filter);
					const stories = res.items;
					return stories;
				},
				staleTime: Infinity,
			}
		})
	})


	useEffect(() => {
		setLoading(true)
		if (
			!datas.length ||
			datas.some((data) => data.isLoading)
		) {
			return;
		}

		datas.forEach((data, index) => {
			if (data.isSuccess) {
				const value = index === 0 ? { new: data.data } : { recent: data.data }
				setNotifications(pre => ({ ...pre, ...value }))
			}
			//  else {
			// 	const valueErr = index === 0 ? { new: [data.error?.message || ""] } : { recent: [data.error?.message || ""] }
			// 	setErrors(pre => ({ ...pre, ...valueErr }))
			// }
		})
		setLoading(false)
	}, [JSON.stringify(datas.map(data => data.data))])


	return {
		errors,
		loading,
		notifications,
		total: notifications?.new?.length + notifications?.recent?.length,
	};


};

export default useNotification;
